import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { showLoading, hideLoading } from '../utils/loader';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private auth: AuthService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    showLoading();
    
    const token = localStorage.getItem("token");
    
    if(token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        }
      })
    }
    
    return next.handle(request).pipe( tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        
        hideLoading();
      }
    },
      (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status !== 401 && err.status !== 403) {
          hideLoading();
          return;
        }
        hideLoading();
        this.router.navigate(['auth','login']);
        this.auth.setUser = null;
      }
    }));
  }
}
