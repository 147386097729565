import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-operations-modal',
  templateUrl: './operations-modal.component.html',
  styleUrls: ['./operations-modal.component.scss']
})
export class OperationsModalComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef, private router: Router) { }

  ngOnInit(): void {
  }

  goToInmediateOperations(): void {
    this.bsModalRef.hide();
    this.router.navigate(['/operaciones','cambio-inmediato'])
  }

  goToInterbankOperations(): void {
    this.bsModalRef.hide();
    this.router.navigate(['/operaciones','cambio-interbancario'])
  }
  
  goToNegotiatedOperations(): void {
    this.bsModalRef.hide();
    this.router.navigate(['/operaciones','cambio-negociado'])
  }

}
