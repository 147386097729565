import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { first, map, Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanDeactivate<unknown> {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | boolean {

    return this.authService.getUser.pipe(
      first(),
      map(user => {
        if(user) {
          return true
        } else {
          this.router.navigate(['auth/login']);
          return false
        }
      })
    )

  }
  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean> | boolean {      
      return this.authService.getUser.pipe(
        first(),
        map(user => {
          if(user) {
            this.router.navigate(['/']);
            return false;
          } else {
            return true;
          }
        })
      )
  }
  
}
