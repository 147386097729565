
<div class="card">
  <div class="close-button" (click)="bsModalRef.hide()">
    <img src="../../../../../../assets/card/close.svg" alt="">
  </div>
  <ul class="operations-modal">
    <h4 class="mt-0 mb-5">¿Qué operación deseas realizar?</h4>
    <li class="operations-modal__item">
      <div class="d-flex align-items-center" (click)="goToInmediateOperations()" >
        <img src="../../../../../../assets/dashboard/home/inmediate.svg" alt="">
        <p class="mx-3 my-0">Cambio <br> <span>Inmediato</span></p>
      </div>
      <div class="operations-modal__item-go" (click)="goToInmediateOperations()">
        <img src="../../../../../../assets/dashboard/home/arrow-right.svg" alt="">
      </div>
    </li>
    <li class="operations-modal__item">
      <div class="d-flex align-items-center" (click)="goToInterbankOperations()">
        <img src="../../../../../../assets/dashboard/home/interbank.svg" alt="">
        <p class="mx-3 my-0">Transferencia <br> <span>Interbancaria</span></p>
      </div>
      <div class="operations-modal__item-go" (click)="goToInterbankOperations()">
        <img src="../../../../../../assets/dashboard/home/arrow-right.svg" alt="">
      </div>
    </li>
<!--     <li class="operations-modal__item">
      <div class="d-flex align-items-center" (click)="goToNegotiatedOperations()">
        <img src="../../../../../../assets/dashboard/home/negociate.svg" alt="" (click)="goToNegotiatedOperations()">
        <p class="mx-3 my-0">Cambio <br> <span>Negociado</span></p>
      </div>
      <div class="operations-modal__item-go" (click)="goToNegotiatedOperations()">
        <img src="../../../../../../assets/dashboard/home/arrow-right.svg" alt="">
      </div>
    </li> -->
    <p class="mt-5 mb-0">Realiza tus cambios o transferencias interbancarias de forma rápida y segura con Billex</p>
  </ul>
</div>