import { Injectable } from '@angular/core';
import { CanActivate, Router} from '@angular/router';
import { first, map, Observable} from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  
  constructor(private authService: AuthService, private router: Router){}
  
  canActivate(): Observable<boolean> | boolean {
    return this.authService.getUser.pipe(
      first(),
      map(user => {
        if(user) {
          this.router.navigate(['/']);
          return false;
        } else {
          return true;
        }
      })
    )
  }
  
}
