import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, map, of, switchMap } from 'rxjs'
import Pusher from 'pusher-js';
import { DefaulExchangeRateResponse, ExchangeRate } from 'src/app/models/pusher';
import { HomeService } from '../home/home.service';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class PusherService {

  pusher: any;
  user: any;
  url: string = environment.API_URL;
  clientId!: string;

  exchangeRatesChannel: any;
  negotiatedOperationsChannel: any;

  exchange_rate: BehaviorSubject<ExchangeRate> = new BehaviorSubject<ExchangeRate>({
    compra: "",
    venta: ""
  });

  get getExchangeRate(){
    return this.exchange_rate.asObservable();
  }
  
  set setExchangeRate(exchange: ExchangeRate) {
    this.exchange_rate.next(exchange);
  }
  
  
  constructor(private http: HttpClient, private homeService: HomeService, private authService: AuthService) {

/*     this.authService.getUser.subscribe(resp => {
      this.clientId = resp?.assigned_client?.id;
    }); */
    this.authService.getUser.pipe(
      switchMap(user => {
        this.clientId = user?.assigned_client?.id;
        if (this.clientId) {
          // Get the default exchange rate whenever the user changes
          return this.getExchangeRateDefault();
        } else {
          // If there's no client ID, return an Observable that emits null
          return of(null);
        }
      })
    ).subscribe(exchangeRate => {
      if (exchangeRate) {
        // Only update exchange_rate if the exchange rate is not null
        this.setExchangeRate = exchangeRate;
      }
    });


    if(this.clientId) {
      this.getExchangeRateDefault();
    }
    
    if(localStorage.getItem("user")) {
      this.user = JSON.parse(localStorage.getItem("user") || "")
    }

    //Ver Logs
    Pusher.logToConsole = false;

    this.pusher = new Pusher("478629757e21e4156e6f", {
      cluster: 'us2',
    })

    this.exchangeRatesChannel = this.pusher.subscribe( `exchange-rate.${this.user.user.id}`)?.bind("get-exchangerate", (data: ExchangeRate) => {

      let compra = data.compra;
      let venta = data.venta;


      this.setExchangeRate = {
        compra,
        venta
      };

    }, (error: any) => {
      console.log("error pusher service",error);
      
    })

    //Negotiated Operations
    this.negotiatedOperationsChannel = this.pusher.subscribe(`negotiated-operations`)?.bind("get-negotiated-operations", (data: any) => {
      this.homeService.getOffersAmount(this.clientId);
    }, (error: any) => {
      console.log("error pusher service", error);
    })

    
    
  }

  /* getExchangeRateDefault(): void {
    this.http.get<DefaulExchangeRateResponse>(`${this.url}/dashboard/exchange-rate`).subscribe((response: DefaulExchangeRateResponse) => {   
      this.setExchangeRate = response.data.exchange_rate;
    })
  } */
  getExchangeRateDefault(): Observable<ExchangeRate> {
    return this.http.get<DefaulExchangeRateResponse>(`${this.url}/dashboard/exchange-rate`).pipe(
      map(response => response.data.exchange_rate)
    );
  }
}
