<div class="layout">
  <div #sidebar class="layout__sidebar">
    <div class="row layout__sidebar_header">
      <div class="col d-flex justify-content-between">
        <img src="../../../assets/logo.svg" alt="Billex" width="92" height="92" [routerLink]="['/']" style="cursor: pointer;">
        <img src="../../../assets/header/close.svg" alt="" (click)="toggleMenu()" class="menu_responsive">
      </div>
    </div>
    <div>
      <div class="row pt-5 pb-3 layout__sidebar_list">
        <div class="col-12 layout__sidebar_list_item" 
        [ngClass]="currentLocation === '' ? 'item-active' : ''" 
        [routerLink]="['']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/home.svg" alt="">
          <p>Dashboard</p>
        </div>
        <div class="col-12 layout__sidebar_list_item" 
        [ngClass]="currentLocation === 'operaciones' ? 'item-active' : ''" 
        [routerLink]="['/operaciones']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/operations.svg" alt="">
          <p>Mis Operaciones</p>
        </div>
<!--         <div class="col-12 layout__sidebar_list_item"
        [ngClass]="currentLocation === 'ofertas-de-cambio' ? 'item-active' : ''" 
        [routerLink]="['/ofertas-de-cambio']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/exchange-rate.svg" alt="">
          <p>Ofertas de Cambio</p>
        </div> -->
        <div class="col-12 layout__sidebar_list_item"
        [ngClass]="currentLocation === 'cuentas' ? 'item-active' : ''" 
        [routerLink]="['/cuentas']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/accounts.svg" alt="">
          <p>Mis Cuentas</p>
        </div>
        <!-- <div class="col-12 layout__sidebar_list_item"
        [ngClass]="currentLocation === 'alertas' ? 'item-active' : ''" 
        [routerLink]="['/alertas']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/alerts.svg" alt="">
          <p>Mis Alertas</p>
        </div> -->
        <div class="col-12 layout__sidebar_list_item"
        [ngClass]="currentLocation === 'indicadores' ? 'item-active' : ''" 
        [routerLink]="['/indicadores']" (click)="toggleMenu()">
          <img src="../../../assets/sidebar/indicators.svg" alt="">
          <p>Mis Indicadores</p>
        </div>
      </div>
      <div class="row">
        <div class="col-12 button">
          <button class="w-100" (click)="openOperationsModal()" (click)="toggleMenu()">
            <img src="../../../assets/buttons/plus.svg" alt="">
            Nueva Operación
          </button>
        </div>
      </div>
      <div class="row layout__sidebar_slider">
        <carousel [isAnimated]="true" class="d-flex justify-content-center">
          <slide>
            <img src="../../../assets/carousel/image1.svg" alt="first slide" style="display: block; width: 225px; height: 107px;">
          </slide>
          <slide>
            <img src="../../../assets/carousel/image2.svg" alt="first slide" style="display: block; width: 225px; height: 107px;">
          </slide>
          <slide>
            <img src="../../../assets/carousel/image1.svg" alt="first slide" style="display: block; width: 225px; height: 107px;">
          </slide>
        </carousel>
      </div>
    </div>
    <div class="row layout__sidebar_footer" (click)="logout()" style="cursor: pointer;">
      Cerrar Sesión
    </div>
  </div>
  <div class="layout__main">
    <div class="layout__main_header" [ngStyle]="widthNavigator >= 1200 ? {'display': 'flex'}: {'display': 'none'}">
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <div class="layout__main_header_exchange">
              <p>COMPRA: <span>{{ (exchangeRate$ | async).venta | number:'1.4-4' }}</span></p>
              <div class="layout__main_header_exchange_line"></div>
              <p>VENTA: <span>{{ (exchangeRate$ | async).compra | number:'1.4-4'}}</span></p>
            </div>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <div class="layout__main_header_right">
              <!--<div class="layout__main_header_right_alert shadow">
                <img src="../../../assets/header/alert.svg" alt="Alert">
                <div class="layout__main_header_right_alert_num"></div>
              </div>-->
              <div class="layout__main_header_right_user">
                <div class="dropdown">
                  <a class="btn btn-secondary dropdown-toggle text-dark d-flex shadow" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../../assets/header/avatar2.jpg" alt="" width="40p" height="40">
                    <div class="user_info">
                      <p *ngIf="(user$ | async)?.user?.document_type.id === 2">{{ (user$ | async)?.user?.name }} {{ (user$ | async)?.user?.last_name }}</p>
                      <p *ngIf="(user$ | async)?.user?.document_type.id !== 2">{{ (user$ | async)?.user?.name }}</p>
                      <!-- <span class="fw-bold" *ngIf="(user$ | async)?.user?.document_type.id === 2">
                        {{ ((user$ | async)?.assigned_client?.name + ' ' +
                           (user$ | async)?.assigned_client?.last_name + ' ' +
                           (user$ | async)?.assigned_client?.mothers_name) | slice:0:30 }}
                        {{ ((user$ | async)?.assigned_client?.name + ' ' +
                        (user$ | async)?.assigned_client?.mothers_name + ' ' +
                        (user$ | async)?.assigned_client?.last_name).length >= 30 ? '...': '' }}
                      </span> -->
                      <span class="fw-bold" *ngIf="(user$ | async)?.assigned_client?.document_type_id === 2">
                        {{ ((user$ | async)?.assigned_client?.name + ' ' +
                           (user$ | async)?.assigned_client?.last_name + ' ' +
                           (user$ | async)?.assigned_client?.mothers_name) | slice:0:30 }}
                      </span>
                      <span class="fw-bold"*ngIf="(user$ | async)?.assigned_client?.document_type_id !== 2">
                        {{ ((user$ | async)?.assigned_client?.name)}}
                      </span>
                    </div>
                  </a>
                  <ul class="dropdown-menu w-100 py-4 shadow" aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-item" [routerLink]="['/perfil']" routerLinkActive="router-link-active" >
                      <div class="header_icon">
                        <img src="../../../assets/header/user.svg" alt="">
                      </div>
                      <p style="font-weight: 600;">Mi Cuenta</p>
                    </li>
                    <li class="dropdown-item" [routerLink]="['/perfil/cambiar-perfil']" routerLinkActive="router-link-active" >
                      <div class="header_icon">
                        <img src="../../../assets/header/refresh.svg" alt="">
                      </div>
                      <p>Cambiar Perfil</p>
                    </li>
                    <li class="dropdown-item" [routerLink]="['/perfil/editar-contraseña']">
                      <div class="header_icon">
                        <img src="../../../assets/header/edit.svg" alt="">
                      </div>
                      <p>Cambiar contraseña</p>
                    </li>
                    <li class="dropdown-item" (click)="logout()">
                      <div class="header_icon">
                        <img src="../../../assets/header/logout.svg" alt="">
                      </div>
                      <p>Cerrar Sesión</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout__main_header_responsive" [ngStyle]="widthNavigator <= 1200 ? {'display': 'flex'}: {'display':'none'}">
      <div class="container h-100">
        <div class="row h-100">
          <div class="col-4 h-100">
            <div #menuRef class="d-flex align-items-center justify-content-start h-100 hamburguer" (click)="toggleMenu()">
            </div>
          </div>
          <div class="col-4 h-100">
            <div class="d-flex justify-content-center align-items-center h-100">
              <img src="../../../assets/header/logo.svg" alt="">
            </div>
          </div>
          <div class="col-4 h-100">
            <div class="layout__main_header_responsive_right">
              <div class="layout__main_header_responsive_right_alert shadow">
                <img src="../../../assets/header/alert.svg" alt="Alert">
                <div class="layout__main_header_responsive_right_alert_num"></div>
              </div>
              <div class="layout__main_header_responsive_right_user">
                <div class="dropdown">
                  <a class="btn btn-secondary dropdown-toggle text-dark d-flex" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <img src="../../../assets/header/avatar2.jpg">
                  </a>
                  <ul class="dropdown-menu w-100 py-4 shadow" aria-labelledby="dropdownMenuLink">
                    <li class="dropdown-item" [routerLink]="['/perfil']" routerLinkActive="router-link-active" >
                      <div class="header_icon">
                        <img src="../../../assets/header/user.svg" alt="">
                      </div>
                      <p style="font-weight: 600;">Mi Cuenta</p>
                    </li>
                    <li class="dropdown-item" [routerLink]="['/perfil/cambiar-perfil']" routerLinkActive="router-link-active">
                      <div class="header_icon">
                        <img src="../../../assets/header/refresh.svg" alt="">
                      </div>
                      <p>Cambiar Perfil</p>
                    </li>
                    <li class="dropdown-item">
                      <div class="header_icon">
                        <img src="../../../assets/header/edit.svg" alt="">
                      </div>
                      <p>Cambiar contraseña</p>
                    </li>
                    <li class="dropdown-item" (click)="logout()">
                      <div class="header_icon">
                        <img src="../../../assets/header/logout.svg" alt="">
                      </div>
                      <p>Cerrar Sesión</p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="layout__main_header_responsive_exchange" [ngStyle]="widthNavigator <= 1200 ? {'display': 'flex'}: {'display': 'none'}">
      <p>COMPRA: <span>{{ (exchangeRate$ | async).venta | number:'1.4-4' }}</span></p>
      <div class="layout__main_header_responsive_exchange-line"></div>
      <p>VENTA: <span>{{ (exchangeRate$ | async).compra | number:'1.4-4'}}</span></p>
    </div>
    <div class="layout__main_content">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>