import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { GraphResponse } from 'src/app/models/graph';
import { IndicatorsResponse } from 'src/app/models/indicators';
import { GetAlertResponse } from 'src/app/models/alert';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  url : string = environment.API_URL;
  alertsDashboard!: number;
  
  constructor(private http: HttpClient) {}


  getGraphData(type: number): Observable<GraphResponse> {
    return this.http.get<GraphResponse>(`${this.url}/dashboard/graphs?type=${type}`);
  }

  getIndicators(clientId: string): Observable<IndicatorsResponse> {
    return this.http.get<IndicatorsResponse>(`${this.url}/dashboard/indicators?client_id=${clientId}`);
  }

  getOffersAmount(clientId: string) {
    this.http.get<GetAlertResponse>(`${this.url}/dashboard/negotiated-operations?client_id=${clientId}`).subscribe(resp => {
      this.alertsDashboard = resp.data.alerts;
    })
  }
}
