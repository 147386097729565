import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { PusherService } from 'src/app/services/pusher/pusher.service';
import { OperationsModalComponent } from 'src/app/views/dashboard/home/modals/operations-modal/operations-modal.component';

@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class DefaultLayoutComponent implements OnInit {

  user$: Observable<any>;
  exchangeRate$!: Observable<any>;

  currentLocation: string = "";
  bsModalRef?: BsModalRef;

  widthNavigator: number = window.innerWidth;

  @ViewChild('sidebar', {static: false}) sidebarRef!: ElementRef;
  @ViewChild('menuRef', {static: false}) menuRef!: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.widthNavigator = event.target.innerWidth;    
  }


  constructor(
    private modalService: BsModalService, 
    private route: Router, 
    private authService: AuthService,
    private pusherService: PusherService
    ) {
    this.route.events.subscribe(resp => {
      if(resp instanceof NavigationEnd) {
        this.currentLocation = resp.url.split("/")[1];
      }
      
    })
    this.user$ = this.authService.getUser;
    this.user$.subscribe(resp=>{});
    this.exchangeRate$ = this.pusherService.getExchangeRate;
    this.exchangeRate$.subscribe(resp =>{});
  }

  ngOnInit(): void {
    
  }

  openOperationsModal() {
    this.bsModalRef = this.modalService.show(OperationsModalComponent);
    this.bsModalRef.setClass("modal-dialog-centered")
  }

  toggleMenu(): void {
    if(this.menuRef?.nativeElement.classList.contains('opened')) {
      this.menuRef?.nativeElement.classList.remove('opened');
      this.sidebarRef?.nativeElement.classList.remove('active');
    } else {      
      this.menuRef?.nativeElement.classList.add('opened');
      this.sidebarRef?.nativeElement.classList.add('active');
    }
  }

  logout() {
    this.authService.logout();
  }
}
