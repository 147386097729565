import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Client } from 'src/app/models/client';
import { AccountTypeRegisterResponse, BankListRegisterResponse, GetCountriesResponse, GetDepartmentsResponse, GetDistrictsResponse, GetEconomicResponse, GetPNDocumentTypesResponse, GetProfessionsResponse, GetProvincesResponse, ValidateRUCResponse } from 'src/app/models/register';
import { LoginResponse, User, UserContainer } from 'src/app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  user$ = new BehaviorSubject<any>(null);
  companyRegister: any;

  get getUser() {
    return this.user$.asObservable();
  }

  set setUser(user: any) {
    this.user$.next(user);
  }

  url: string = environment.API_URL;

  user!: any;

  constructor(private http: HttpClient, private router: Router) {
    this.user = localStorage.getItem("user");
    this.user !== null ? this.user$.next(JSON.parse(this.user)) : this.user$.next(null);

    this.user$.subscribe(resp => {
      this.user = resp;
    })
  }

  login(credentials: { email: string, password: string }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(`${this.url}/login`, credentials);
  }

  logout(): void {
    this.http.post<{ success: boolean }>(`${this.url}/logout`, null).subscribe(resp => {
      if(resp.success) {
        this.user$.next(null)
        localStorage.removeItem('user');
        this.router.navigate(['/auth','login']);
      }
    })
  }

  resetPassword(email: string): Observable<any> {
    return this.http.post(`${this.url}/forgot-password`, email);
  }

  updateClient(client: Client): void {

    let newUser = {
      ...this.user,
      user: this.user?.user,
      assigned_client: client
    }

    this.setUser = newUser; 

    localStorage.setItem('user', JSON.stringify(newUser));
  }

  updateUser(user: User): void {
    let newUser = {
      ...this.user,
      user: user
    }

    this.setUser = newUser;

    localStorage.setItem('user', JSON.stringify(newUser))
  }

  getPNDocumentTypes(): Observable<GetPNDocumentTypesResponse> {
    return this.http.get<GetPNDocumentTypesResponse>(`${this.url}/register/document-types`);
  }

  getPJDocumentTypes(): Observable<GetPNDocumentTypesResponse> {
    return this.http.get<GetPNDocumentTypesResponse>(`${this.url}/register/representatives-document-types`);
  }

  existsCompany(ruc: string): Observable<any> {
    return this.http.get(`${this.url}/register/exists-company?ruc=${ruc}`);
  }

  existsPerson(dni:string, document_type: string): Observable<any>{
    let params = new HttpParams()
    .append('document_number', dni)
    .append('document_type', document_type);
    return this.http.get(`${this.url}/register/exists-person`,{params});
  }

  validateRUC(ruc: string): Observable<ValidateRUCResponse> {
    return this.http.get<ValidateRUCResponse>(`${this.url}/register/validate-ruc?ruc=${ruc}`);
  }

  validateDNI(dni: string): Observable<any> {
    return this.http.get(`${this.url}/register/validate-dni?dni=${dni}`);
  }

  getEconomicActivities(): Observable<GetEconomicResponse> {
    return this.http.get<GetEconomicResponse>(`${this.url}/register/economic-activities`);
  }

  getDepartments(): Observable<GetDepartmentsResponse> {
    return this.http.get<GetDepartmentsResponse>(`${this.url}/register/departments`);
  }

  getProvinces(department_id: number): Observable<GetProvincesResponse> {
    return this.http.get<GetProvincesResponse>(`${this.url}/register/provinces?department_id=${department_id}`);
  }

  getDistricts(province_id: number): Observable<GetDistrictsResponse> {
    return this.http.get<GetDistrictsResponse>(`${this.url}/register/districts?province_id=${province_id}`);
  }

  getCountries(): Observable<GetCountriesResponse> {
    return this.http.get<GetCountriesResponse>(`${this.url}/register/countries`);
  }

  getProfessions(): Observable<GetProfessionsResponse> {
    return this.http.get<GetProfessionsResponse>(`${this.url}/register/professions`);
  }

  getBanks(): Observable<BankListRegisterResponse> {
    return this.http.get<BankListRegisterResponse>(`${this.url}/register/banks`);
  }

  getAccountTypes(): Observable<AccountTypeRegisterResponse> {
    return this.http.get<AccountTypeRegisterResponse>(`${this.url}/register/account-types`);
  }

  getFichaRUC(file: any): Observable<any> {
    let formData = new FormData();
    formData.append("file", file)
    return this.http.post(`${this.url}/register/ficha-ruc`, formData);
  }

  uploadFile(file: any, client_id: any): Observable<any> {
    let formData = new FormData();
    formData.append("file", file)
    formData.append("client_id", client_id)
    return this.http.post(`${this.url}/register/upload-file`, formData);
  }

  registerCompany(company: any): Observable<any> {
    return this.http.post(`${this.url}/register/register-company`, company)
  }

  registerPersonal(person: any): Observable<any> {
    return this.http.post(`${this.url}/register/register-person`, person);
  }
}
